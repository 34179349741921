import * as React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
// import Seo from "../components/seS
import styled from "styled-components";
import { useEffect } from "react";
import { motion } from "framer-motion";
import IntroVideo from "../components/IntroVideo";
import { unmountComponentAtNode } from "react-dom";
import PageCopy from "../components/PageCopy";

const PageContainer = styled.div`
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  video {
    width: 100%;
    padding: 2rem;
  }
  h2 {
    font-size: 5em;
    text-align: center;
    padding: 1rem 0;
    margin: 1rem;
    line-height: 0.9em;
    color: var(--text-color);
    span {
      display: inline-block;
      padding: 1rem;
    }
  }
  @media (max-width: 790px) {
    h2 {
      font-size: 3.5rem;
    }
  }
`;

export default function IndexPage({ data, pageContext }) {
  const content = data.pageCopy.homepageIntro.toUpperCase().split("\n");

  return (
    <PageContainer>
      {/* <Seo title="Home" /> */}
      <IntroVideo />
      {content.map((line, i) => {
        return (
          <motion.h2
            animate={{
              y: [-100 * i - 100, 0],
              opacity: [0, 1],
            }}
            transition={{ delay: i * 2, duration: 2 }}
          >
            {line}
          </motion.h2>
        );
      })}
    </PageContainer>
  );
}

export const query = graphql`
  query {
    pageCopy: sanityWebsiteCopy {
      homepageIntro
    }
  }
`;
