import React from "react";
import IntroVideo from "../images/intro.mp4";
import { BiVolumeMute } from "react-icons/bi";
import styled from "styled-components";

const VideoContainer = styled.div`
  position: relative;
  div {
    position: absolute;
    top: 0;
    right: 0;
    padding: 3rem;
    color: #ccc;
    font-size: 2rem;
    margin: auto;
  }
`;

export default function () {
  const [mute, setMute] = React.useState(true);

  const clickHandler = () => {
    setMute(!mute);
  };

  return (
    <VideoContainer>
      <video
        id="introVideo"
        autoPlay
        muted={mute ? true : false}
        onClick={clickHandler}
      >
        <source src={IntroVideo} type="video/mp4" />
      </video>
      <div onClick={clickHandler}>{mute ? <BiVolumeMute /> : ""}</div>
    </VideoContainer>
  );
}
